.menu {
  display: flex;
  flex-direction: column;
}

.menu__button {
  padding: 10px;
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: baseline;
}

.menu__header {
  width: 8rem;
  flex-shrink: 0;
}

.menu__subheader {
  font-size: 1rem;
  font-style: italic;
  color: #404040;
}

.menu__return-button {
  display: block;
  width: 100%;
  margin-top: .5rem;
}

.menu__return-button-icon {
  margin-right: .5rem;
  position: relative;
  top: 6px;
  font-size: 2rem;
}

.menu__return-button__small {
  display: block;
  width: fit-content;
  padding: .3rem;
  margin: 0;
}

.menu__return-button__small span {
  padding: .3rem;
  margin: 0;
  color: black;
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: none;
}

.menu__return-button__small span:hover {
  background-color: rgb(140, 26, 17);
  color: rgb(191, 190, 190);
  transition: 0.1s;
}

.menu__button:hover {
  background-color: rgb(140, 26, 17);
  color: rgb(191, 190, 190);
  transition: 0.1s;
}

.menu__button:hover .menu__subheader {
  color: rgb(191, 190, 190);
  transition: 0.1s;
}

.menu__info {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.menu__info img {
  border-radius: 50%;
  max-width: 200px;
  min-width: 80px;
  max-height: 200px;
  min-height: 80px;
  object-fit: cover;
  flex: 1 1 200px;
}

.menu__info p {
  margin-right: 5%;
}

.menu__greeting {
  flex: 0 2 800px;
  max-width: 1000px;
}

.menu__greeting h2 {
 color: #72147e;
}

@media screen and (max-width: 750px) {
  .menu__button {
    padding: 10px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .menu__button {
    padding: 10px;
    width: 95%;
    font-size: 1rem;
  }

  .menu__subheader {
    font-size: .75rem;
  }

  .menu__return-button-icon {
    font-size: 1.3rem;
    top: 4px
  }
}
