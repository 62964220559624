body {
  background-color: rgb(168, 35, 168);
  margin: 0;
  padding: 2rem;
  color: black;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

#background-vid {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(5px);
  animation: fadeInAnimation ease-out 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.about {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
  width: 95%;
}

.name {
  color: white;
  opacity: 0.9;
  text-align: center;
  font-size: 2.8em;
  font-weight: 600;
}

.terminal {
  padding: 30px;
  background-color: rgb(191, 190, 190);
  border: solid black 1.5px;
  position: relative;
  cursor: "pointer";
}

.terminal__outer {
  background-color: rgb(191, 190, 190);
  width: 80%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12vh;
  box-shadow: 15px 15px rgba(0, 0, 0, 0.6);
  margin-bottom: 100px;
}

.terminal__outer__small {
  width: 24%;
  min-width: 350px;
  margin-left: 10%;
  margin-top: 0;
}

.terminal__title {
  position: relative;
  background-color: rgb(191, 190, 190);
  top: -55px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -25px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0px;
  font-size: 1em;
  width: fit-content;
  color: rgb(140, 26, 17);
  border-left: black solid 1px;
  border-right: black solid 1px;
}

.terminal__selector {
  display: flex;
  justify-content: space-between;
  padding: 3px 3px 0 3px;
}

.terminal__selector label {
  user-select: none;
}

.terminal__selector:first-of-type {
  border-bottom: 2px solid grey;
  padding-bottom: 5px
}

.window {
  background-color: #72147e;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
  border-radius: 5px;
  border: 2px solid #49474a;
}

.window__bar {
  height: 25px;
  background-color: #39373a;
  color: lightgray;
  font-size: 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #49474a;
}

.window__bar-filler {
  width: 80px;
}

.window__bar-circles {
  display: flex;
  align-items: center;
}

.window__bar-circle {
  background-color: #fe5f57;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

.window__text {
  font-size: 1rem;
  margin-top: 0;
  color: wheat;
  font-weight: lighter;
}

.window__bar-circle__yellow {
  background-color: #febc2e;
}

.window__bar-circle__green {
  background-color: #27c83f;
}

.no-margin {
  margin: 0px;
}

/* Screen door effect from: http://aleclownes.com/2017/02/01/crt-display.html */
.crt::before {
  content: " ";
  display: block;
  position: fixed;
  width: 100vw;
  height: 100lvh;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 2;
  background-size:
    100vw 2px,
    3px 100vh;
  pointer-events: none;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 750px) {
  body {
    font-size: medium;
    padding: 0px;
  }

  .terminal__outer {
    width: 90%;
    margin-top: 5vh;
  }

  .terminal__outer__small {
    width: 50%;
    min-width: 350px;
    margin-left: 5%
  }

  .window {
    width: 95%;
  }

  .terminal {
    padding: 15px;
  }

  .terminal__title {
    top: -36px;
  }
}

@media screen and (max-width: 450px) {
  .name {
    font-size: 1.75rem;
  }

  .window__bar {
    display: none;
  }

  .window__text {
    display: none;
  }

  .window {
    background-color: transparent;
    border: none;
  }

  .terminal__outer {
    width: 85%;
    margin-bottom: 50px;
  }

  .terminal__outer__small {
    width: 85%;
    min-width: 0;
    margin-left: 5%
  }

  .terminal {
    padding: 10px;
  }

  .terminal__title {
    top: -30px;
  }

  .project__card {
    border-bottom: 1px solid grey;
  }
}

@media screen and (max-width: 375px) {

}

@media only screen and (min-width: 1600px) {
  .window {
    width: 75%;
  }
}
