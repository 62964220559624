.project {
    display: flex;
    max-width: 950px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.project__card {
    flex: 1 1;
    width: 100%;
    padding: 10px 10px 40px 10px;
    margin: 20px;
    margin-top: 0px;
    border-bottom: 3px solid grey;
}

.project__card h2 {
    margin-top: 0px;
    color: #72147e;
}

.project__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.project__text {
    flex: 3 1 300px;
    padding: 10px;
    width: 300px;
}

.project__image {
    flex: 1 1 auto;
    padding: 10px;
    margin-right: 20px;
    min-width: 100px;
    max-width: 300px;

}

@media screen and (max-width: 450px) {
    .project__card {
      border-bottom: 1px solid grey;
    }
  }