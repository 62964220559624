.resume__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
  width: 95%;
}

.resume__element {
  flex: 0 1 auto;
  padding: 8px 2%;
}

.resume__element p {
  line-height: 1.5rem;
}

.resume__element h1 {
  color: #72147e;
}

.resume__element h4 {
  margin-top: 0px;
  color: #f21170;
  margin-bottom: 5px;
}

.resume__element h5 {
  margin-bottom: 3px;
}

.resume__element ul {
  margin-top: 2px;
  list-style-type: "- ";
}

.resume__element h2 {
  color: #72147e;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

.resume__photo {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
